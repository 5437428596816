import { EventEmitter, Injectable, Injector } from '@angular/core';
import { AccountInfo, AuthenticationResult, BrowserAuthErrorCodes, PublicClientApplication } from '@azure/msal-browser';
import { DatalexClient, IGenericBE } from '@datalex-software-as/datalex-client';
import { BehaviorSubject, Observable, Subscriber, firstValueFrom } from 'rxjs';
import { InformationOverlayService, MessageTypes } from '../components/UI/info-overlay/information-overlay.service';
import { SystemCacheService } from './system-cache.service';
import { environment } from 'src/environments/environment';
import { MsalBroadcastConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MicrosoftGraphService } from './graph.service';
import { DeviceService } from './device.service';

export interface IAzureSettings {
  clientId: string
  authority: string
  redirectUri?: string
  tennantId: string
  scopes: string[]
  clientScopes: string[]
}

interface IAzureInitOptions {
  userInitiated: boolean,
  config: IAzureSettings
}

interface ILoginStatus {
  isLoggedIn: boolean,
  firstEmit: boolean
}


type CallbackFunction<T, Args extends any[]> = (...args: [...Args, string]) => Observable<T>;
interface UseTokenWithCallbackParams<T, Args extends any[]> {
  redirectUri: string;
  callback: CallbackFunction<T, Args>;
  args: Args;
}


/**
 * Service for managing authentication using MSAL (Microsoft Authentication Library) in an Angular application.
 * Provides methods for initializing the application, handling user sign-in and sign-out,
 * and obtaining and using authentication tokens.
 *
 * @remarks
 * This service integrates with the DatalexClient for fetching application settings and
 * relies on the \@azure/msal-browser library for authentication.
 *
 * @export
 * @class DatalexMsalService
 */

@Injectable({
  providedIn: 'root'
})
export class DatalexMsalService {
  isDeviseDesktop!: boolean;

  private errorThrownInSession: boolean = false;

  constructor(private dlxClient: DatalexClient, private info: InformationOverlayService, private injector: Injector, private angMsal: MsalService, private graph: MicrosoftGraphService, private deviceService: DeviceService) {

    this.isDeviseDesktop = this.deviceService.getDeviceType() === 'Desktop';
  }

  //public emitters
  ready: EventEmitter<boolean> = new EventEmitter();
  error: EventEmitter<any> = new EventEmitter();
  $accessToken: EventEmitter<string> = new EventEmitter();


  //private emitters
  private clientReady = new EventEmitter();


  //Public fields
  public msalClient!: PublicClientApplication;
  public azureSettings!: IAzureSettings;
  public accessToken: string | null = null;
  public auth!: AuthenticationResult | null;
  public broadcast!: MsalBroadcastService

  //Private fields
  private account!: AccountInfo | null;
  private allAccounts!: AccountInfo[] | null;

  /**
   * Generates the HTTP request body for fetching application settings.
   * The body is a JSON string containing the specified Type and searchText properties.
   *
   * @returns {string} The JSON string representing the HTTP request body.
   */
  httpBody(): string {
    return JSON.stringify({
      Type: "MicrosoftGraphClientConfig",
      searchText: "1"
    });
  }

  /**
   * Generates the HTTP headers for the POST request to fetch application settings.
   * Includes the Authorization header with the DLX client's token and content-type header.
   *
   * @returns {Headers} The Headers object containing the configured HTTP headers.
   */
  httpHeaders(): Headers {
    return new Headers({
      "Authorization": `Token ${this.dlxClient.token}`,
      "X-DLX-SessionId": this.dlxClient.sessionId,
      'content-type': "text/json"
    });
  }

  /**
 * Retrieves application settings by making a POST request to the DLX client's web URL.
 * The request body and headers are configured using helper methods.
 *
 * @returns {Promise<IGenericBE[]>} A Promise that resolves to an array of generic backend objects (IGenericBE).
 */
  public async getAppSettings(): Promise<IGenericBE[]> {
    // Configure HTTP options for the POST request
    const httpOptions = {
      method: 'POST',
      body: this.httpBody(),
      headers: this.httpHeaders(),
    };

    // Make a POST request to the DLX client's web URL to fetch application settings
    return fetch(this.dlxClient.webURL + "/FindGenerics", httpOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<IGenericBE[]>
      })

  }

  /**
 * Initializes the application by fetching app settings, unpacking Azure settings,
 * creating a public client, and acquiring an initial authentication token.
 * @remarks This method is typically called during the application startup.
 * @param {IAzureInitOptions} options - options to initiate Microsoft Graph Client.
 
 */
  initialize({ userInitiated, config }: IAzureInitOptions) {
    // this.isDeviseDesktop = this.deviceService.getDeviceType() === 'Desktop';

    // if (!this.angMsal.instance) {
    // Create a public azure client using the unpacked Azure settings and Initialize the MSAL client and acquire an initial authentication token
    this.createPublicClient(config).initialize().then(() => {
      const cfg: MsalBroadcastConfiguration = {
        eventsToReplay: 0
      };
      this.broadcast = new MsalBroadcastService(this.angMsal.instance, this.angMsal, cfg);

      this.subscribeToEvents()
      this.aquireToken(config, userInitiated);
      try {
        this.graph.init({
          scopes: config.clientScopes,
          account: this.account!,
          msalClient: this.angMsal.instance as PublicClientApplication
        });
      } catch (error) {
        throw error;
      }

      this.updateLoginStatus(config.tennantId);
    });
    // } else {
    //   this.angMsal.initialize().subscribe({
    //     next: () => {
    //       this.subscribeToEvents()
    //       this.aquireToken(config, userInitiated);
    //       this.updateLoginStatus(config.tennantId);
    //     }
    //   })
    // }
  }


  private subscribeToEvents() {
    if (!this.angMsal) {
      console.error('MSAL instance is not initialized.');
      return;
    }

    // Example: subscribing to login success events
    this.broadcast.msalSubject$.subscribe({
      next: (value) => {
        console.warn('msalBroadcastService:', value)
      }
    })
    // msalSubject$
    //   .pipe(filter(msg => msg.eventType === 'msal:loginSuccess'))
    //   .subscribe((msg) => {
    //     
    //     // Handle login success
    //   });

    // Add more subscriptions as needed
  }

  /**
   * Unpacks Azure settings from an array of generic backend (IGenericBE) objects.
   *
   * @param {IGenericBE[]} json - The array of generic backend objects containing Azure settings.
   * @throws {Error} Throws an error if there's an issue parsing the JSON or accessing the required properties.
   */
  unpackAzureSettings(json: IGenericBE[]) {

    // Check if the first object has the 'ValueS1' property
    if (json[0].ValueS1) {
      // Parse the JSON string stored in 'ValueS1' and assign it to the 'azureSettings' property
      let settings = JSON.parse(json[0].ValueS1) as IAzureSettings;
      //settings.clientId = "1081bfa2-8752-4ceb-8e0b-fa2a56310381";
      return settings;
    }
    else {
      throw new Error("No MicrosoftGraphClientConfig generic record found")
    }

  }

  /**
   * Creates a PublicClientApplication instance using the provided Azure settings.
   *
   * @param {IAzureSettings} azSettings - The Azure settings used to configure the PublicClientApplication.
   */
  private createPublicClient(azSettings: IAzureSettings) {
    this.angMsal.instance = new PublicClientApplication({
      auth: {
        clientId: azSettings.clientId,
        authority: azSettings.authority,
        redirectUri: azSettings.redirectUri,
      },
      cache: {
        cacheLocation: "localStorage"
      },
    });

    // this.angMsal.instance

    return this.angMsal.instance
  }

  /**
   * Acquires an authentication token using the provided Azure settings.
   * Depending on the availability of valid accounts and existing access tokens,
   * this method either performs a token acquisition or initiates a sign-in process.
   *
   * @param {IAzureSettings} azSettings - The Azure settings used for token acquisition.
   * @param {boolean} userInitiated - if the method is called by the user or automaticly.
   */
  public aquireToken(azSettings: IAzureSettings, userInitiated: boolean): void {
    // Implementation details for token acquisition logic go here
    try {
      // Check if there are valid accounts and an existing access token
      if (this.checkAccounts()) {
        // Attempt to acquire the token using the existing accounts and token
        this.azureAquireToken(azSettings, userInitiated);
      } else {
        // No valid accounts or access token, initiate the sign-in process
        this.azureSignIn(azSettings, userInitiated);
      }
    } catch (error: any) {
      // Log an error message if token acquisition fails

      if (error.includes("BrowserAuthError: popup_window_error")) {
        this.info.addMessage({
          type: MessageTypes.Warning,
          message: "Vi kunne ikke åpne Microsoft påloggingsvindu, er popup vinduer blokkert?",
          sender: "DatalexMsalService",
        })
      }
    }
  }

  /**
   * Acquires an authentication token silently using the provided Azure settings.
   * If the silent acquisition fails, it falls back to initiating a sign-in process.
   *
   * @param {IAzureSettings} azSettings - The Azure settings used for token acquisition.
   * @param {boolean} userInitiated - if the method is called by the user or automaticly.
   */
  azureAquireToken(azSettings: IAzureSettings, userInitiated: boolean): void {
    /**
     * Attempt to acquire the token silently using the MSAL client.
     * If the silent acquisition fails, initiate a sign-in process.
     */


    this.angMsal.acquireTokenSilent({ scopes: azSettings.scopes }).subscribe({
      next: (response) => {
        // Update the access token and authentication details
        this.accessToken = response.accessToken;
        this.auth = response;
        this.angMsal.instance.setActiveAccount(response.account)
        this.updateLoginStatus(azSettings.tennantId);
        this.$accessToken.emit(response.accessToken);
      },
      error: () => {

        // Silent acquisition failed, initiate a sign-in process
        this.azureSignIn(azSettings, userInitiated);
      }
    })
  }




  /**
   * Initiates a user sign-in process using a popup dialog for acquiring an authentication token.
   *
   * @param {IAzureSettings} azSettings - The Azure settings used for the sign-in process.
   * @param {boolean} userInitiated - if the method is called by the user or automaticly.
   */
  private azureSignIn(azSettings: IAzureSettings, userInitiated: boolean): void {

    const accounts = this.angMsal.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.angMsal.instance.setActiveAccount(accounts[0]);
    }

    /**
     * Perform a user sign-in using a popup or redirect dialog for token acquisition.
     * If the sign-in process fails, log an error message.
     */

    if (!this.isDeviseDesktop) {
      this.signInRedirect(azSettings, userInitiated);
    } else {
      this.signInPopup(azSettings);
    }

  }

  /**
 * Initiates and handles the redirection promise after a user signs in using MSAL.
 *
 * @param {IAzureSettings} azSettings - The Azure settings containing authentication scopes.
 * @param {boolean} userInitiated - if the method is called by the user or automaticly.
 */

  signInRedirect(azSettings: IAzureSettings, userInitiated: boolean, redirectUri?: string) {
    this.angMsal.instance.handleRedirectPromise().then((tokenResponse) => {
      if (!tokenResponse) {
        const accounts = this.angMsal.instance.getAllAccounts();
        if (accounts.length === 0) {
          // No user signed in
          if (userInitiated === false) return
          this.angMsal.loginRedirect({
            scopes: azSettings.scopes,
            redirectUri: redirectUri ? redirectUri : undefined
          });
        }
      } else {
        // Update the account, access token, and authentication details
        this.account = tokenResponse.account;
        this.accessToken = tokenResponse.accessToken;
        this.auth = tokenResponse;

        this.updateLoginStatus(azSettings.tennantId);
        this.$accessToken.emit(tokenResponse.accessToken);
      }
    }).catch((err) => {
      // Handle error
      console.error("AquireTokenFailed:", err);
    });
  }

  /**
 * Initiates the sign-in process using a popup and handles the authentication response.
 *
 * @param {IAzureSettings} azSettings - The Azure settings containing authentication scopes.
 */

  signInPopup<T>(azSettings: IAzureSettings, subscriber?: Subscriber<string>/*, resolver?: ((value: T | PromiseLike<T>) => void)*/) {


    this.angMsal.loginPopup({ scopes: azSettings.scopes, redirectUri: environment.environmentName === 'development' ? location.protocol + '//' + location.host : azSettings.redirectUri })
      .subscribe({
        next: (response) => {


          // Update the account, access token, and authentication details
          this.account = response.account;
          this.accessToken = response.accessToken;
          this.auth = response;
          this.angMsal.instance.setActiveAccount(response.account);

          if (subscriber ) {
            subscriber.next(response.accessToken);
          }
          // if (resolver) {

          // }
          this.$accessToken.emit(response.accessToken);
          this.updateLoginStatus(azSettings.tennantId);

        },
        error: (error: any) => {
          // Log an error message if the sign-in process fails

          if (error.message.includes(BrowserAuthErrorCodes.popupWindowError)) {
            this.info.addMessage({
              type: MessageTypes.Warning,
              message: "Vi kunne ikke åpne Microsoft påloggingsvindu, er popup vinduer blokkert?",
              sender: "DatalexMsalService",
            })
          }
        }
      })
  }

  /**
   * Checks for the existence of user accounts using the MSAL client.
   * If an account is found, it sets it as the active account.
   *
   * @returns {boolean} Returns true if at least one account is found and set as active, otherwise false.
   */
  private checkAccounts(): boolean {
    try {
      
      // Get the first account from the MSAL client

      this.account = this.angMsal.instance.getAllAccounts()[0];
      this.allAccounts = this.angMsal.instance.getAllAccounts();
      if (this.account) {
        // Set the found account as the active account
        this.angMsal.instance.setActiveAccount(this.account);
        return true;
      } else {
        // No account found
        return false;
      }
    } catch {
      // An error occurred during the account check
      return false;
    }
  }



  public signOut(): void {
    // Perform a logout popup using the MSAL client

    const account = this.angMsal.instance.getActiveAccount()!
    this.angMsal.logoutPopup({ account: account, logoutHint: account.idTokenClaims!.login_hint })
      .subscribe({
        next: () => {
          this.account = null;
          this.accessToken = null;
          this.auth = null;
          this.updateLoginStatus("");
        }
      })
  }

  public useToken(redirectUri: string, callback?: () => any): Observable<string> {
    const sys = this.injector.get(SystemCacheService);
    if (sys.microsoftGraphClientConfig === null) {
      throw new Error("Client missing configuration")
    }
    return new Observable((sub) => {

      if (this.auth && this.auth.expiresOn && this.auth.expiresOn.getTime() < +new Date()) {
        (async () => {
          const response = await firstValueFrom(this.angMsal.acquireTokenPopup({ scopes: sys.microsoftGraphClientConfig!.scopes, redirectUri: redirectUri }))
          this.account = response.account;
          this.accessToken = response.accessToken;
          this.$accessToken.emit(response.accessToken);
          this.auth = response;
          sub.next(response.accessToken);

        })();

        return;
      }
      // Get the current timestamp
      const now = +new Date();
      // Get the expiration timestamp of the current token
      const expiresOn = this.auth?.expiresOn?.getTime();

      if (expiresOn && sys.microsoftGraphClientConfig) {
        // Check if the token is still valid for more than 5 minutes
        if ((expiresOn - now) > 300000) {
          sub.next(this.auth!.accessToken);
        } else if ((expiresOn - now) < 0) {
          // Token has expired, perform a login popup to acquire a new token
          if (window.innerWidth < 476) {
            this.angMsal.loginPopup({ scopes: sys.microsoftGraphClientConfig.scopes }).subscribe({
              next: (response) => {
                this.account = response.account;
                this.accessToken = response.accessToken;
                this.$accessToken.emit(response.accessToken);
                this.auth = response;
                sub.next(response.accessToken);
              }
            });
          } else {
            this.angMsal.instance.handleRedirectPromise().then((tokenResponse) => {
              if (!tokenResponse) {
                const accounts = this.angMsal.instance.getAllAccounts();
                if (accounts.length === 0) {
                  this.angMsal.loginRedirect({ scopes: sys.microsoftGraphClientConfig!.scopes, redirectUri: redirectUri });
                }
              } else {
                // Update the account, access token, and authentication details
                this.account = tokenResponse.account;
                this.accessToken = tokenResponse.accessToken;
                this.$accessToken.emit(tokenResponse.accessToken);
                this.auth = tokenResponse;
                localStorage.setItem("azAccessToken", tokenResponse.accessToken);
                setTimeout(() => {
                  try {
                    localStorage.removeItem("azAccessToken")
                  } catch (error) {

                  }
                }, 5000)
              }
            }).catch((err) => {
              console.error("AquireTokenFailed:", err);
            });
          }
        } else {
          // Token is still valid, refresh it silently
          this.angMsal.acquireTokenSilent({ scopes: sys.microsoftGraphClientConfig.scopes }).subscribe({
            next: (response) => {
              this.accessToken = response.accessToken;
              this.$accessToken.emit(response.accessToken);
              this.auth = response;
              sub.next(response.accessToken);
            },
            error: () => {

            }
          });
        }
      } else {
        // No existing token, perform a login popup to acquire a new token
        if (window.innerWidth < 476) {
          this.signInRedirect(sys.microsoftGraphClientConfig!, true, redirectUri);

        } else {
          this.signInPopup(sys.microsoftGraphClientConfig!, sub);
        }
      }
    });
  }

  assignAndSendAuthResponse(auth: AuthenticationResult, sub: Subscriber<string>) {
    this.account = auth.account;
    this.accessToken = auth.accessToken;
    this.$accessToken.emit(auth.accessToken);
    this.auth = auth;
    sub.next(auth.accessToken);
  }



  isLoggedInToSharePoint(tennantId: string): boolean {
    // return this.angMsal && this.account?.tenantId? this.angMsal.getAllAccounts().length > 0 : false;
    const accounts = this.angMsal.instance.getAllAccounts();
    if (accounts.length === 0) return false

    if (this.auth && this.auth.expiresOn && this.auth.expiresOn.getTime() < +new Date()) {
      return false
    }

    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].tenantId === tennantId) {
        this.angMsal.instance.setActiveAccount(accounts[i])
        return true
      }
    }

    return false
  }

  firstEmit = true;

  private _isLoggedInToSharePoint = new BehaviorSubject<ILoginStatus | null>(null);
  public isLoggedInToSharePoint$ = this._isLoggedInToSharePoint.asObservable();

  updateLoginStatus(tennantId: string): void {
    this._isLoggedInToSharePoint.next({ isLoggedIn: this.isLoggedInToSharePoint(tennantId), firstEmit: this.firstEmit });
    this.firstEmit = false
  }


  setAuthResponse(auth: AuthenticationResult) {
    this.account = auth.account;
    this.accessToken = auth.accessToken;
    this.$accessToken.emit(auth.accessToken);
    this.auth = auth;
  }



  // useTokenWithCallback<T, Args extends any[]>({redirectUri, callback, args}: UseTokenWithCallbackParams<T, Args>): Promise<T> {
  //   const sys = this.injector.get(SystemCacheService);
  //   console.log(this.auth!.expiresOn!.getTime() - +new Date());


  //   if (sys.microsoftGraphClientConfig === null) {
  //     throw new Error("Client missing configuration")
  //   }
  //   return new Promise(async (resolve, reject) => {
      
  //       try {

  //         const expiresOn = this.auth!.expiresOn!.getTime();

  //         if (this.auth && expiresOn - +new Date() > 0) 
  //         {
  //           // Get the current timestamp
  //           const now = +new Date();
  //           // Get the expiration timestamp of the current token
            
  //           if ((expiresOn - now) < 300000){
  //             // token is valid but expires but less than 5 minutes, refresh token silently
  //             const silentAuth = await firstValueFrom(this.angMsal.acquireTokenSilent({ scopes: sys.microsoftGraphClientConfig!.scopes }));
  //             this.setAuthResponse(silentAuth);
  //           }
  //         } 
  //         else 
  //         {
  //           // Token has expired, perform login based on device type
  //           let authResult = null;

  //           if(this.deviceService.getDeviceType() === 'Desktop'){
  //             // device is desktop, perform login popup
  //             authResult = await firstValueFrom(this.angMsal.loginPopup({ scopes: sys.microsoftGraphClientConfig!.scopes }))
  //             this.setAuthResponse(authResult);
  //           } else {
  //             // device is mobile or tablet, perform login redirect
  //             authResult = await this.angMsal.instance.handleRedirectPromise();
  //             if (!authResult) {
  //               // check if there is any account, if not perform login redirect
  //               const accounts = this.angMsal.instance.getAllAccounts();
  //               if (accounts.length === 0) {
  //                 await firstValueFrom(this.angMsal.loginRedirect({ scopes: sys.microsoftGraphClientConfig!.scopes, redirectUri: redirectUri }));
  //               }
  //             } else {
  //               // token is acquired, set the auth response                
  //               this.setAuthResponse(authResult);
  //               localStorage.setItem("azAccessToken", authResult.accessToken);
  //               setTimeout(() => {
  //                 try {
  //                       localStorage.removeItem("azAccessToken")
  //                 } catch (error) {

  //                 }
  //               }, 5000)
  //             }
  //           }
  //         }

  //         if (this.auth) {
  //           const result = await firstValueFrom(callback(...args, this.auth.accessToken));
  //           resolve(result);
  //         } else {
  //           // No existing token and failed to aquire a new token
  //           throw new Error("Failed to acquire token")
  //         }

  //       } catch (error: any) {
  //         console.log(error)
  //         try {
  //           if (window.innerWidth < 476) {
  //             this.signInRedirect(sys.microsoftGraphClientConfig!, true, redirectUri);
    
  //           } else {
  //             this.signInPopup(sys.microsoftGraphClientConfig!, undefined, resolve);
  //           }
  //         } catch (error: any) {
  //           reject(new Error(`Failed to execute token Aquisition or to execute callback: ${error.message}`));
  //         }
  //       }
      
  //   })
  // }
}

