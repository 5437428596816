import { inject, Injectable, OnDestroy } from '@angular/core';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { firstValueFrom, Subscription } from 'rxjs';
import { DatalexMsalService } from './datalex-msal.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface AsyncTask {
  name: string;
  arguments: { [key: string]: any };
  lastRun: number | null;
  nextRun:  number | null;
  interval: number;
  task: (args: { [key: string]: any }) => Promise<any>;
  lastStatus: string | number | null;
}

@Injectable({
  providedIn: 'root'
})

export class TaskSchedulerService implements OnDestroy {
  private dlxClient = inject(DatalexClient);
  private dlxMsal = inject(DatalexMsalService);
  private runInterval: number;
  private intervalId: any;
  private tasks: AsyncTask[] = [];
  private subscriptions: Subscription[] = [];

  constructor() {
    this.runInterval = 1000 * 60 * 5;
    this.initializeTasks();
  }


  private initializeTasks(): void {
    this.tasks = [
      {
        name: "Cleanup DocumentSharePoint",
        arguments: {},
        lastRun: null,
        nextRun: null,
        interval: 1000 * 60 * 10,
        task: async (args: any) => {
          return new Promise<string | HttpErrorResponse>(async (resolve, reject) => {
            this.dlxClient.DocumentSharePointEnabled().subscribe({
              next: (enabled) => {
                if (!enabled) {
                  resolve('No SharePoint integration configureds');
                }
                try {
                  const tokenSubscription = this.dlxMsal.useToken(window.location.href).subscribe({
                    next: async (token) => {
                      args.userId = await firstValueFrom(this.dlxClient.ValidateLogin());
                      args.accessToken = token;
                      const cleanupSubscription = this.dlxClient.CleanupDocumentSharePoint(args.userId, args.accessToken).subscribe({
                        next: () => {
                          resolve('OK');
                          cleanupSubscription.unsubscribe();  // Unsubscribe after completion
                        },
                        error: (error: HttpErrorResponse) => {
                          reject(error);
                          cleanupSubscription.unsubscribe();  // Unsubscribe on error
                        }
                      });
                      this.subscriptions.push(cleanupSubscription);  // Store subscription
                    },
                    error: (error) => {
                      reject(error);
                    }
                  });
                  this.subscriptions.push(tokenSubscription);  // Store subscription
                } catch (error) {
                  reject(error);
                }
              }
            })
          });
        },
        lastStatus: null,
      }
    ];
  }

  start(): void {
    this.intervalId = setInterval(async () => {
      const now = Date.now();

      for (const task of this.tasks) {
        if (task.lastStatus !== 404) {
          if (task.nextRun === null || task.nextRun <= now) {
            task.lastRun = now;
            task.nextRun = now + task.interval;
            let result;
            try {
              result = await task.task(task.arguments);
              task.lastStatus = result;
            } catch (error: any) {
              task.lastStatus = error.status;
              console.error(`Task ${task.name} failed:`, error);
            }
          } else {

          }
        }

      }

      console.table(this.tasks.map(task => {
        return {
          name: task.name,
          interval: task.interval,
          lastRun: new Date(task.lastRun ?? '').toLocaleTimeString('nb-NO'),
          nextRun: task.lastStatus === 404 ? 'never' :  new Date(task.nextRun ?? '').toLocaleTimeString('nb-NO'),
          lastStatus: task.lastStatus
        };
      }));
    }, this.runInterval);
  }

  stop(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.unsubscribeAll();
  }

  ngOnDestroy() {
    this.stop();
  }

  private unsubscribeAll(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }
}
