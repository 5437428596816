import { Injectable } from '@angular/core';
import { DatalexClient, IDocumentSharePointExtendedBE } from '@datalex-software-as/datalex-client';
import { ParentReference } from 'src/app/models/GraphAPI';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

export interface WindowWrapper {
  window: Window;
  windowId: string;
  userId: string;
  parentRefrence?: ParentReference;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentCheckoutService {

  constructor(private dlxClient: DatalexClient, private eventEmitterService: EventEmitterService) { 

  }

  docs: IDocumentSharePointExtendedBE[] = [];
  spinners: boolean[] = [];

  popupHandles: WindowWrapper[] = [];
 

  handleCleanUpInterval!: NodeJS.Timeout;

  getCheckedOutDocuments(id: string, sendForceUpdate?: boolean): void {
    this.dlxClient.GetDocumentSharePointExtendedByUserId(id, true, null).subscribe({
      next: (res) => {

        this.docs = Array.isArray(res) ? res : [];
        this.docs.forEach(() => {
          this.spinners.push(false);
        });

        if (sendForceUpdate) {

        }

      },
      error: () => {
        this.docs = [];
        this.spinners = [];
      }
    });
  }

  addDocumentSharePoint(documentID: string, editmode: boolean): void {
    this.dlxClient.GetDocumentSharePointExtendedByDocumentId(documentID).subscribe({
      next: (res) => {
        if(editmode) {
          res.PreviewUrl = null;
        } else {
          res.WebUrl = null;
        }

        this.docs.push(res);
      }
    });
  }

  getDocuments(): IDocumentSharePointExtendedBE[] {
    return this.docs;
  }

  addPopupHandle(handle: WindowWrapper) {

    this.popupHandles.push(handle);

    if (this.handleCleanUpInterval) {
      clearInterval(this.handleCleanUpInterval);
    }

    this.handleCleanUpInterval = setInterval(() => {
      if (this.popupHandles.length === 0) {
        clearInterval(this.handleCleanUpInterval);
        return
      }

      for (let i = 0; i < this.popupHandles.length; i++) {
        if (this.popupHandles[i].window.closed) {
          this.popupHandles.splice(i, 1);
        }
      }
    }, 3000)
  }

  popupHandleLookup(name: string) {
    if (this.popupHandles.length === 0) return false;
    const handle = this.popupHandles.find(i => i.windowId === name);
    return handle ? handle : false
  }

  removePopupHandle(id: string) {
    const index = this.popupHandles.findIndex(i => i.windowId === id);
    if(index > -1) {
      this.popupHandles.splice(index, 1);
    }
  }

  getCheckedoutDocument(id: string){
    return this.docs.find(i => i.DocumentId === id);
  }

}