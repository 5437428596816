import { Injectable } from "@angular/core";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { AccountInfo, InteractionType, PublicClientApplication } from "@azure/msal-browser";

export interface IGraphServiceInitConfig { 
    scopes: string[];
    account: AccountInfo;
    msalClient: PublicClientApplication;
}

@Injectable({
    providedIn: 'root'
})
export class MicrosoftGraphService {
    constructor() { }
    graphClient!: Client;

    init({scopes, account, msalClient}: IGraphServiceInitConfig) {
        // const settings: IAzureSettings = {
        //     clientId: msalService.azureSettings.clientId,
        //     tennantId: msalService.azureSettings.tennantId,
        //     scopes: [
        //         'Files.ReadWrite.All',
        //     ],
        //     authority: msalService.azureSettings.authority,
        // }

        this.graphClient = Client.initWithMiddleware({
            authProvider: new AuthCodeMSALBrowserAuthenticationProvider(
                msalClient,
                {
                    account: account,
                    scopes: scopes,
                    interactionType: InteractionType.Popup,
                },
            ),
            fetchOptions: {
                headers: {
                    'Prefer': 'reconciliation=true'
                }
            }
        });


    }
}
