import { Injectable } from '@angular/core';
import { RandomId } from 'src/app/util/RandomId';
import { MessagesService } from '../../pages/settings/messages/messages.service';

/**
 * 
 */

export enum MessageTypes {
  Info = 0,
  Warning = 1,
  Error = 2,
  News = 3
}


/**
 * Represents an information message with specific properties.
 */
export interface IInformationMessage {
  /**
   * The content of the message.
   */
  message: string;

  /**
   * The sender of the message.
   */
  sender: string;

  /**
   * The unique identifier for the message.
   */
  id?: string;

  /**
   * The type of the message (0 - Info, 1 - Warning, 2 - Error).
   */
  type: MessageTypes
}

/**
 * Injectable service for managing information overlay messages.
 */
@Injectable({
  providedIn: 'root'
})
export class InformationOverlayService {

  constructor(private ms: MessagesService) { }

  /**
   * Array to store error messages.
   */
  public errors: IInformationMessage[] = [];

  /**
   * Array to store warning messages.
   */
  public warnings: IInformationMessage[] = [];

  /**
   * Array to store information messages.
   */
  public informations: IInformationMessage[] = [];

  /**
   * Array to store news messages.
   */
  public news: IInformationMessage[] = [];
  /**
   * Array to store open messages.
   */
  public openInfoMessages: IInformationMessage[] = [];

  /**
   * Removes a specific message from the open information messages.
   *
   * @param {IInformationMessage} msg - The message to be removed.
   */
  public removeMessage(msg: IInformationMessage): void {
    this.openInfoMessages = this.openInfoMessages.filter(openMsg => openMsg.id !== msg.id);
  }

  /**
   * Adds a new message to the open information messages and manages the display duration.
   *
   * @param {IInformationMessage} msg - The message to be added.
   */
  public addMessage({id = RandomId(), message, sender, type}: IInformationMessage): void {
    if (type === MessageTypes.Warning) {
      if (this.openInfoMessages.some(i => i.id === id) || this.warnings.some(i => i.id === id)) return;
    }

    this.openInfoMessages.push({id, message, sender, type});
    this.ms.appendMessages({id, title: sender, content: message, type: type === MessageTypes.Error ? "error" : "info"});

    setTimeout(() => {
      const messageToMove = this.openInfoMessages.shift();
      if (messageToMove) {
        switch(messageToMove.type){
          case 0:
            this.informations.push(messageToMove);
            break;
          case 1:
            this.warnings.push(messageToMove);
            break;
          case 3: 
            this.news.push(messageToMove);
            break;
          case 2:
          default: 
            this.errors.push(messageToMove);
            break;
        }
      }
    }, 10000);
  }
}