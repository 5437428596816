import { EventEmitter, Injectable } from '@angular/core';
import { RandomId } from 'src/app/util/RandomId';

export interface IMessage {
  id: string,
  title: string,
  content: string
  count?: number | null
  type: "info" | "error"
}



@Injectable({
  providedIn: 'root'
})

export class MessagesService {

  constructor() { }
  private _messages: IMessage[] = [];

  public appendMessages(message: IMessage) {
    if(!message.count) {
      message.count = 1;
    }
    this._messages = [...this.messages, message]
    this.change.emit();
  }
  
  public get messages(): IMessage[] {
    return this._messages
  }

  change = new EventEmitter();
  
}
