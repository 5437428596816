import { Injectable, EventEmitter, forwardRef, Inject } from '@angular/core';
import { ContactFilterProvider } from '../util/ContactFilters';
import { CookieProvider } from '../util/Cookies';
import { EventEmitterService, IForcedEventTypeEnum } from './event-emitter.service';
import { DatalexClient, IDocumentCategoryBE, IDocumentTypeBE, IUserLimitedBE, IRoleTypeCategoryBE, IRoleTypeBE, IInvoiceTypeBE, IHourRegistrationTypeBE, IDisciplineBE, IContactLimitedBE, IUserPictureBE, IAccountsBE, IUserRightsBE } from '@datalex-software-as/datalex-client';
import { Router } from '@angular/router';
import { DocumentCheckoutService } from '../components/UI/document-checkout/document-checkout.service';
import { DatalexExceptionHandlerService } from './datalex-exception-handler.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject, filter, from, takeUntil } from 'rxjs';
import { DatalexMsalService, IAzureSettings } from './datalex-msal.service';
import { normalize } from 'path';
import { InformationOverlayService } from '../components/UI/info-overlay/information-overlay.service';
import { TaskSchedulerService } from './task-scheduler.service';
import { CountryService } from './country.service';

@Injectable({
  providedIn: 'root'
})
export class SystemCacheService {
  constructor(
    private cfp: ContactFilterProvider,
    private cookie: CookieProvider,
    private dlxClient: DatalexClient,
    private dlxEx: DatalexExceptionHandlerService,
    public dcs: DocumentCheckoutService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private dlxMsal: DatalexMsalService,
    private info: InformationOverlayService,
    private countryService: CountryService,
    @Inject(forwardRef(() => TaskSchedulerService)) private taskScheduler: TaskSchedulerService
  ) {

  }

  private readonly _destorying$ = new Subject<void>();


  public userId: string = '';
  public setUserId(userId: string = '') {
    this.userId = userId;
  }

  public token: string = '';
  public setToken(token: string = '') {
    this.token = token;
  }
  public sessionId: string = '';
  public setSessionId(sessionId: string = '') {
    this.sessionId = sessionId;
  }

  public webURL: string = '';
  public setWebURL(webURL: string = '') {
    this.webURL = webURL;
  }

  public loggedInUser!: IUserLimitedBE;
  public setLoggedInUser(loggedInUser: IUserLimitedBE) {
    this.loggedInUser = loggedInUser;
  }

  public loggedinUserContact!: IContactLimitedBE;
  public setLoggedinUserContact(loggedInUserContact: IContactLimitedBE) {
    this.loggedinUserContact = loggedInUserContact;
  }

  public allEmployees!: IUserLimitedBE[];
  public allUsers!: IUserLimitedBE[];
  public setAllUsers(users: IUserLimitedBE[]) {
    const _ = this.cfp.filterUsersRemoveAdministrativeUsers(users);
    this.allEmployees = this.cfp.filterRemoveFormerEmployees(_)
    this.allUsers = _;
  }

  public roleTypes!: IRoleTypeBE[];
  public setRoleTypes(roleTypes: IRoleTypeBE[]) {
    this.roleTypes = roleTypes;
  }
  public roleTypeCatergorys!: IRoleTypeCategoryBE[];
  public setRoleTypeCatergorys(roleTypeCatergorys: IRoleTypeCategoryBE[]) {
    this.roleTypeCatergorys = roleTypeCatergorys;
  }

  public discipline!: IDisciplineBE[];
  public setDiciplines(discipline: IDisciplineBE[]) {
    this.discipline = discipline;
  }

  public hourRegistrationMainGroups!: IHourRegistrationTypeBE[];
  public setHourRegistrationMainGroups(hourRegistrationMainGroups: IHourRegistrationTypeBE[]) {
    this.hourRegistrationMainGroups = hourRegistrationMainGroups;
  }

  public invoiceTypes!: IInvoiceTypeBE[];
  public setInvoiceTypes(invoiceTypes: IInvoiceTypeBE[]) {
    this.invoiceTypes = invoiceTypes.filter(type => type.Code === "J" || type.Code === "!" || type.Code === "N")
  }

  public documentTypes: IDocumentTypeBE[] = [];
  public documentTypeIds: string[] = [];
  public setDocumentTypes(documentTypes: IDocumentTypeBE[]) {
    this.documentTypes = documentTypes;
    documentTypes.forEach(type => {
      this.documentTypeIds.push(type.Id);
    })
  }

  public documentCategories!: IDocumentCategoryBE[];
  public setDocumentCategories(documentCategories: IDocumentCategoryBE[]) {
    this.documentCategories = documentCategories;
  }

  public sharepointEnabled!: boolean;
  public setSharepointEnabled(isEnabled: boolean) {
    this.sharepointEnabled = isEnabled;
  }

  public profilePicture!: IUserPictureBE;
  public setProfilePicture(picture: IUserPictureBE) {
    this.profilePicture = picture;
  }

  public loggedInUserAccount!: IAccountsBE;
  public setLoggedInUserAccount(loggedInUserAccount: IAccountsBE) {
    this.loggedInUserAccount = loggedInUserAccount;
  }

  public userRights!: IUserRightsBE[];
  setUserRights(userRights: IUserRightsBE[]) {
    this.userRights = userRights;

  }

  public isMaster!: boolean;
  setIsMaster(isMaster: boolean) {
    this.isMaster = isMaster;
  }

  public isLoggedIn: boolean = false;
  public setIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }
  public MSconnected: boolean = false;
  public setMSconnected(value: boolean) {
    this.MSconnected = value;
  }
  public msAuth: AuthenticationResult | null = null;
  public setMsAuth(value: AuthenticationResult) {
    this.msAuth = value;
    this.setMSconnected(!!value.accessToken)
  }

  public microsoftGraphClientConfig: IAzureSettings | null = null;
  public microsoftGraphEnabled: boolean = false;

  public setMicrosoftGraphClientConfig(value: IAzureSettings) {
    this.microsoftGraphClientConfig = value;
    this.microsoftGraphEnabled == !!value;
  }


  public clearCache() {
    this.userId = this.token = this.webURL = "";
    (this.loggedInUser as unknown) =
      (this.loggedinUserContact as unknown) =
      (this.allEmployees as unknown) =
      (this.allUsers as unknown) =
      (this.roleTypes as unknown) =
      (this.roleTypeCatergorys as unknown) =
      (this.discipline as unknown) =
      (this.hourRegistrationMainGroups as unknown) =
      (this.invoiceTypes as unknown) =
      (this.userRights as unknown) =
      (this.loggedInUserAccount as unknown) =
      (this.profilePicture as unknown) = null;
  }

  public isReady = new EventEmitter<SystemCacheService>();

  ready() {
    this.setIsLoggedIn(true);
    this.sysReady = true;
    this.isReady.emit(this);
  }

  executeWhenReady<T extends any[], R>(callback: (...args: T) => R, ...args: T): R | any {
    try {
      return callback(...args);
    } catch (error) {

      console.error(error);

      this.isReady.subscribe({
        next: () => {
          return callback(...args);
        },
      });
    }
  }

  executeWhenReadyObservable<T extends any[], R>(callback: (...args: T) => R, ...args: T): Observable<R> {
    return new Observable<R>((subscriber) => {
      try {
        const result: R = callback(...args);
        if (!result) throw { code: 'ERR:FINAL', name: callback.name, message: 'No result from callback' };
        subscriber.next(result);
        subscriber.complete();
        return () => { };
      } catch (error) {
        console.error(error);
        const subscription = this.isReady.subscribe({
          next: () => {
            try {
              const result: R = callback(...args);
              if (!result) throw { code: 'ERR:FINAL', name: callback.name, message: 'No result after retry' };

              subscriber.next(result);
              subscriber.complete();
            } catch (error) {
              subscriber.error(error);
              console.error(error);
            }
          },
          error: (err: any) => {
            subscriber.error(err)
            console.error(err);
          },
        });

        return () => subscription.unsubscribe();
      }
    });
  }


  sysReady: boolean = false;


  public updateSystemChahce(token: string, userid: string, url: string, sessionId: string, callback?: () => any | void) {
    this.setToken(token);
    this.setWebURL(url);
    this.setUserId(userid);
    this.setSessionId(sessionId);



    this.dlxClient.initializeClient(token, url, sessionId);

    this.dlxClient.GetHourRegistrationTypes().subscribe({
      next: (r1) => {
        this.setHourRegistrationMainGroups(r1);

        this.dlxClient.GetInvoiceTypes().subscribe({
          next: (r2) => {
            this.setInvoiceTypes(r2);

            this.dlxClient.GetRoleTypeCategorys().subscribe({
              next: (r3) => {
                this.setRoleTypeCatergorys(r3);

                this.dlxClient.GetRoleTypes().subscribe({
                  next: (r4) => {
                    this.setRoleTypes(r4);

                    this.dlxClient.GetDisciplines().subscribe({
                      next: (r5) => {
                        this.setDiciplines(r5);
                        this.dlxClient.GetDocumentTypes().subscribe({
                          next: (r7) => {
                            this.setDocumentTypes(r7);

                            this.dlxClient.GetDocumentCategorysByIndex(null).subscribe({
                              next: (r8) => {
                                this.setDocumentCategories(r8);

                                this.dlxClient.FindUsersLimited("", true).subscribe({
                                  next: (r9) => {
                                    this.setAllUsers(r9);

                                    this.dlxClient.ValidateLogin().subscribe({
                                      next: (r10) => {
                                        // this.dlxClient.GetSystemSettings().subscribe({
                                        //   next: (settings) => {
                                        //      console.log(settings);
                                        //   }
                                        // })
                                        this.countryService.initCountryService();
                                        this.setUserId(r10);
                                        this.cookie.set('userid', r10);

                                        this.dlxClient.GetUserLimitedById(r10).subscribe({
                                          next: (r11) => {
                                            this.setLoggedInUser(r11);
                                            this.dcs.getCheckedOutDocuments(r11.Id);
                                            this.dlxClient.GetUserPictureByUserId(r11.Id).subscribe({
                                              next: (r12) => {
                                                this.setProfilePicture(r12);
                                                this.dlxClient.GetContactLimitedById(r11.ContactId).subscribe({
                                                  next: (r13) => {
                                                    this.setLoggedinUserContact(r13)

                                                    this.dlxClient.GetAccounts(r13.AccountsId!).subscribe({
                                                      next: (r14) => {

                                                        this.dlxClient.GetUser(r11.Id).subscribe({
                                                          next: (r15) => {

                                                            this.dlxClient.ValidateMaster().subscribe({
                                                              next: (r16) => {
                                                                this.setIsMaster(r16);

                                                                this.dlxClient.DocumentSharePointEnabled().subscribe({
                                                                  next: (r6) => {
                                                                    this.setSharepointEnabled(r6);
                                                                  }
                                                                });

                                                                this.setUserRights(r15.UserRights)
                                                                this.setLoggedInUserAccount(r14)
                                                                if (callback) {
                                                                  callback();
                                                                }

                                                                this.eventEmitterService.sendForceUpdate({ type: IForcedEventTypeEnum.Default });

                                                                const isPdfViewerInUrl = window.location.href.includes('pdfviewer');
                                                                if (isPdfViewerInUrl === false) {


                                                                  this.dlxMsal.getAppSettings()
                                                                    .then((res) => {
                                                                      let settings = this.dlxMsal.unpackAzureSettings(res);
                                                                      if (settings) {
                                                                        this.setMicrosoftGraphClientConfig(settings)
                                                                        this.dlxMsal.initialize({ userInitiated: false, config: settings });
                                                                      }
                                                                    })
                                                                    .catch(() => {
                                                                      const existing = this.info.warnings.find(msg => msg.id === "GraphConfigMissing");
                                                                      if (existing) return;

                                                                      this.info.addMessage({
                                                                        type: 1,
                                                                        id: "GraphConfigMissing",
                                                                        message: "Datalex er ikke integrert med SharePoint, Kontakt Datalex support for mer informasjon.",
                                                                        sender: "System"
                                                                      })
                                                                    })


                                                                  this.taskScheduler.start();
                                                                  this.ready();

                                                                }
                                                                this.routeToMyCases()
                                                              }
                                                            })
                                                          }
                                                        })

                                                      },
                                                      error: this.dlxEx.exception.bind(this)
                                                    })


                                                  },
                                                  error: this.dlxEx.exception.bind(this)
                                                })
                                              },
                                              error: this.dlxEx.exception.bind(this)
                                            })
                                          }, error: this.dlxEx.exception.bind(this)
                                        })
                                      }, error: this.dlxEx.exception.bind(this)
                                    });
                                  }, error: this.dlxEx.exception.bind(this)
                                })
                              }, error: this.dlxEx.exception.bind(this)
                            });
                          }, error: this.dlxEx.exception.bind(this)
                        });
                      }, error: this.dlxEx.exception.bind(this)
                    });
                  }, error: this.dlxEx.exception.bind(this)
                });
              }, error: this.dlxEx.exception.bind(this)
            });
          }, error: this.dlxEx.exception.bind(this)
        });
      }, error: this.dlxEx.exception.bind(this)
    });
  }

  routeToMyCases() {
    if (this.router.url !== "/") return;
    this.router.navigate(['/shortlist']);
  }

}