import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IDocumentLimitedGrid } from '../components/pages/selected-case-page/document/document-grid/document-grid.component';

export enum IForcedEventTypeEnum {
  Default = "0", //interface IDefault
  DocumentsUpdated = "1", //interface IDocChange
  DocumentCheckedIn = "2", //interface IDocumentLockRemoved
  BestEventEver = "3", //interface IBest
  AddedMissingRoleForHours = "4_hours",
  AddedMissingRoleForCosts = "4_costs"
}

export interface IForcedEvent {
  type: IForcedEventTypeEnum
}

export interface IDefault {
  // types
}
export interface IUpdateDocumentGridRow {
  document: IDocumentLimitedGrid;
  rowId: number | null

}
export interface IDocumentCheckedIn {
  id: string
}
export interface IBest {
  // types
}

export interface IAddedMissingRoleForHours {
}

export interface IAddedMissingRoleForsCosts {

}

// Mapping type to connect enum values to interfaces
type EventTypeToInterfaceMap = {
  [IForcedEventTypeEnum.Default]: IDefault;
  [IForcedEventTypeEnum.DocumentsUpdated]: IUpdateDocumentGridRow;
  [IForcedEventTypeEnum.DocumentCheckedIn]: IDocumentCheckedIn;
  [IForcedEventTypeEnum.BestEventEver]: IBest;
  [IForcedEventTypeEnum.AddedMissingRoleForHours]: IAddedMissingRoleForHours;
  [IForcedEventTypeEnum.AddedMissingRoleForCosts]: IAddedMissingRoleForsCosts;
};

// Union type for IForcedEvent with specific event interfaces
type IForcedEventUnion = {
  [K in IForcedEventTypeEnum]: { type: K } & EventTypeToInterfaceMap[K];
}[IForcedEventTypeEnum];



@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  private subject = new Subject<IForcedEventUnion>();

  constructor() { }

  sendForceUpdate(ev: IForcedEventUnion) {
    this.subject.next(ev);
  }

  getForceUpdateEvent(): Observable<IForcedEventUnion> {
    return this.subject.asObservable();
  }

}


// private updateDocumentGridSubject = new Subject<IUpdateDocumentGridEvent>();



// updateDocumentGrid(id: string): void {
//   this.updateDocumentGridSubject.next({ id });
// }


// updateDocumentGridObservable(): Observable<IUpdateDocumentGridEvent> {
//   return this.updateDocumentGridSubject.asObservable();
// }