import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DatalexClient } from '@datalex-software-as/datalex-client';



@Injectable({
  providedIn: 'root'
})
export class DatalexExceptionHandlerService {
  
  constructor(@Inject(DatalexClient) private dlxClient: DatalexClient, private router: Router) { 
  }
  private _error!: HttpErrorResponse;

  set error(error: HttpErrorResponse) {
    this._error = error
  }
  get error(): HttpErrorResponse {
    return this._error;
  }

  exception(err: HttpErrorResponse) {  
    const {status} = err;


    switch (status) {
      case 403:
        this.dlxClient.token = ""; 
        this.dlxClient.webURL = "";
        
        this.router.navigate([''], {queryParams: {action: 'logout'}});  
        break;

      case 500:
      default:
        console.warn(err);
        break;
    }
  }
}
