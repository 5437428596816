import { Injectable } from '@angular/core';
import { DatalexClient, ICountryBE } from '@datalex-software-as/datalex-client';
import * as CountryData from '../../assets/CountryData.json';

export interface IDefaultCountry {
  code: string,
  country: ICountryBE
}

export interface ICountry {
  Country: string,
  Nationality: string,
  CountryCode: string,
  CountryNumber: number | string,
  ZipCodeChars: string | number
}

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  public norway: IDefaultCountry = {
    code: "NO",
    country: {} as ICountryBE
  }

  public allCountries: ICountryBE[] = [];

  constructor(private dlxClient: DatalexClient) {
  }

  initCountryService() {
    this.dlxClient.GetCountryByCode('NO').subscribe({
      next: (res) => {
        this.norway.code = res.Code;
        this.norway.country = res;
      }
    })

    this.dlxClient.FindCountries('').subscribe({
      next: (res) => {
        this.allCountries = res;
      }
    })
  }

  findCountriesBySearchText(searchText: string): Promise<ICountryBE[]> {
    return new Promise((resolve, reject) => {
      this.dlxClient.FindCountries(searchText).subscribe({
        next: (res) => {
          resolve(res)
        },
        error: (err) => {
          reject(err)
        }
      })
    })
  }
}