import { ICaseContactBE, IRoleTypeBE, IUserLimitedBE } from "@datalex-software-as/datalex-client";


export class ContactFilterProvider {
  constructor() { }

  public GetCaseRole(contacts: ICaseContactBE[], id: string) {
    return contacts.filter(contact => contact.RoleTypeId.toLowerCase() === id.toLowerCase())[0].ContactName;
  }

  public GetRoleId(roles: IRoleTypeBE[], code: string): string {
    return roles.filter(role => role.Code === code)[0].Id;
  }

  public filterUsersRemoveAdministrativeUsers(users: IUserLimitedBE[]) {
    return users.filter((user) => user.Username.toLowerCase() !== 'DatalexScheduler'.toLowerCase() && user.Username.toLowerCase() !== 'dladmin'.toLowerCase());
  }

  public filterRemoveFormerEmployees(users: IUserLimitedBE[]) {
    return users.filter((user) => {
      if (user.EmployedTo === null) return true;
      const to = +new Date(user.EmployedTo);
      const now = +new Date();
      return to > now;
    });

  }

  public FilterUser(users: IUserLimitedBE[], userId: string): IUserLimitedBE {
    return users.filter((user) => user.Id.includes(userId))[0];
  }

}
